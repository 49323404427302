<div
  class="wrapper main-spinner"
  [ngClass]="{
    'nav-collapsed': iscollapsed,
    'menu-collapsed': iscollapsed
  }"
  [dir]="'ltr'">
  <div
    appSidebar
    class="app-sidebar d-print-none"
    (toggleHideSidebar)="toggleHideSidebar($event)"
    [ngClass]="{ 'hide-sidebar': hideSidebar }">
    <app-sidebar
      (logoutEmit)="logout()"
      [menuGroups]="routes | async"
      [logoUrl]="'assets/images/agricrm_logo.png'"
      [userData]="user"
      [userClaims]="groupClaims">
      <span
        mainLogo
        class="text align-middle"
        >AgriCRM</span
      >
    </app-sidebar>
    <div class="sidebar-background"></div>
  </div>
  <app-navbar
    (toggleHideSidebar)="toggleHideSidebar($event)"
    [logoUrl]="'assets/images/agricrm_logo.png'">
    <ul class="navbar-nav">
      <li
        class="nav-item"
        *ngIf="hasAccess('Organization.Messages')">
        <app-message-unread-notification></app-message-unread-notification>
      </li>
      <li class="nav-item">
        <app-lang-selector></app-lang-selector>
      </li>
    </ul>
  </app-navbar>
  <div class="main-panel">
    <div class="main-content">
      <div class="content-wrapper">
        <div class="container-fluid">
          <router-outlet></router-outlet>
          <div
            class="provider-container"
            *ngIf="(loginType | async) === loginTypeEnum.PFZ">
            <span>{{ 'APPLICATION.PROVIDERS.pfzInfo' | translate }}</span>
            <img src="assets/images/pfz-logo.png" />
            <img src="assets/images/fundusze-promocji-logo.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
