<ng-container [formGroup]="group">
  <lib-richtext-editor-form-control
    [formControlName]="model.id"
    [name]="model.name"
    [placeholder]="model.placeholder"
    [editorOptions]="model.editorOptions"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    [readonly]="model.disabled"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)"></lib-richtext-editor-form-control>
</ng-container>
