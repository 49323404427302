import * as i0 from "@angular/core";
var ConfigService = /** @class */ (function () {
    function ConfigService() {
        this.setConfigValue();
    }
    ConfigService.prototype.setConfigValue = function () {
        this.templateConf = {
            layout: {
                variant: 'Light',
                dir: 'ltr',
                customizer: {
                    hidden: false,
                },
                sidebar: {
                    collapsed: false,
                    size: 'sidebar-md',
                    // tslint:disable-next-line: max-line-length
                    backgroundColor: 'man-of-steel',
                    /* If you want transparent layout add any of the following mentioned classes to backgroundColor of sidebar option :
                      bg-glass-1, bg-glass-2, bg-glass-3, bg-glass-4, bg-hibiscus,
                      bg-purple-pizzaz, bg-blue-lagoon, bg-electric-viloet, bg-protage, bg-tundora
                    */
                    backgroundImage: true,
                    backgroundImageURL: 'assets/img/sidebar-bg/01.jpg',
                },
            },
        };
    };
    ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
