<section class="container-limit">
  <div
    class="container-fluid px-0"
    *ngIf="formModel$ | async as formModel">
    <form
      class="form-horizontal ng-dynamic-form"
      [formGroup]="formGroup">
      <fieldset class="w-100">
        <dynamic-ng-bootstrap-form
          class="row no-gutters"
          [group]="formGroup"
          [layout]="formLayout"
          [model]="formModel">
          <ng-template
            modelType="GROUP"
            align="START"
            let-legend="legend">
            <p
              *ngIf="legend"
              class="form-group-legend m-0 mb-1">
              {{ legend }}
            </p>
          </ng-template>
        </dynamic-ng-bootstrap-form>
      </fieldset>
    </form>
    <div class="d-flex justify-content-end gap-16 w-100">
      <button
        class="btn btn-secondary"
        type="button"
        (click)="cancel()">
        {{ 'APPLICATION.FORM.cancel' | translate }}
      </button>
      <button
        class="btn btn-primary"
        type="button"
        (click)="addClick()">
        {{ 'APPLICATION.FORM.add' | translate }}
      </button>
    </div>
  </div>
</section>
