<div
  class="container-fluid px-0"
  [appSpinner]="displaySpinner$ | async">
  <div>
    <span
      class="badge"
      [ngClass]="sectionClass">
      {{ farmAuditRequirement.section | enumTranslateKey: 'APPLICATION.DICTIONARIES.FARM_AUDIT_SECTION_TYPE' | translate }}
    </span>
    <div class="mt-2">
      <span class="mr-1">{{ farmAuditRequirement.orderNumber }}.</span>
      <span>{{ farmAuditRequirement.text }}</span>
      <span
        *ngIf="farmAuditRequirement.hintText"
        class="ml-1 font-italic"
        >({{ farmAuditRequirement.hintText }})</span
      >
    </div>
  </div>

  <form
    class="form-horizontal ng-dynamic-form"
    [formGroup]="formGroup"
    *ngIf="formGroup && formModel$ | async as formModel">
    <fieldset>
      <dynamic-ng-bootstrap-form
        class="row farmAuditRequirementFormRow"
        [group]="formGroup"
        [layout]="formLayout"
        [model]="formModel">
        <ng-template
          modelType="GROUP"
          align="START"
          let-legend="legend">
          <p
            *ngIf="legend"
            class="form-group-legend m-0 mb-1">
            {{ legend }}
          </p>
        </ng-template>
      </dynamic-ng-bootstrap-form>
    </fieldset>
  </form>
</div>
