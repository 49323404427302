/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ng-bootstrap/ng-bootstrap";
import * as i2 from "./navbar.component";
import * as i3 from "@ngx-translate/core";
var styles_NavbarComponent = [];
var RenderType_NavbarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavbarComponent, data: {} });
export { RenderType_NavbarComponent as RenderType_NavbarComponent };
export function View_NavbarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "nav", [["class", "header-navbar navbar navbar-expand-lg navbar-light bg-faded header-menu"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.NgbNavbar, [], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "navbar-header d-flex align-items-center d-lg-none justify-content-between"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-primary-outline btn-icon navbar-toggle d-lg-none float-left"], ["data-toggle", "collapse"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidebar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "ph ph-list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "img", [["class", "logo-farmportal d-lg-none"], ["mainLogo", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "navbar-container"]], null, null, null, null, null)), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.logoUrl; _ck(_v, 5, 0, currVal_0); }); }
export function View_NavbarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-navbar", [], null, null, null, View_NavbarComponent_0, RenderType_NavbarComponent)), i0.ɵdid(1, 49152, null, 0, i2.NavbarComponent, [i3.TranslateService], null, null)], null, null); }
var NavbarComponentNgFactory = i0.ɵccf("app-navbar", i2.NavbarComponent, View_NavbarComponent_Host_0, { logoUrl: "logoUrl" }, { toggleHideSidebar: "toggleHideSidebar" }, ["*"]);
export { NavbarComponentNgFactory as NavbarComponentNgFactory };
