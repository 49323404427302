import { LoggerService } from 'crmcloud-core';
import { AuthStoreService } from './services/auth-store.service';
var IdpOrganizationGuard = /** @class */ (function () {
    function IdpOrganizationGuard(authStore, logger) {
        this.authStore = authStore;
        this.logger = logger;
    }
    IdpOrganizationGuard.prototype.canActivate = function (route, state) {
        // use separate interface, do not reuse CoponentClaimsDto
        var requiredPermission = route.data;
        return this.checkPermissions(requiredPermission);
    };
    IdpOrganizationGuard.prototype.canActivateChild = function (childRoute, state) {
        var requiredPermission = childRoute.data;
        return this.checkPermissions(requiredPermission);
    };
    IdpOrganizationGuard.prototype.checkPermissions = function (requiredPermission) {
        if (!requiredPermission.componentKey) {
            // if there are no requirements defined
            // assume that the route can be freely accessed
            return true;
        }
        var userClaims = this.authStore.getUserClaims;
        if (!userClaims || userClaims.length == 0) {
            var message = '[IdpOrganizationGuard] No groups defined in user claims.';
            this.logger.trackException(new Error(message));
            return false;
        }
        return requiredPermission.permissions.some(function (c) {
            var fullPermission = requiredPermission.componentKey + '.' + c;
            return userClaims.some(function (c) { return c === fullPermission; });
        });
    };
    return IdpOrganizationGuard;
}());
export { IdpOrganizationGuard };
