import { EventEmitter, OnInit, ElementRef } from '@angular/core';
import { SidebarListDirective } from './sidebarlist.directive';
import { SidebarDirective } from './sidebar.directive';
var SidebarLinkDirective = /** @class */ (function () {
    function SidebarLinkDirective(sidebarList, sidebar, el) {
        this.el = el;
        this.toggleEmit = new EventEmitter();
        this.sidebarList = sidebarList;
        this.sidebar = sidebar;
    }
    Object.defineProperty(SidebarLinkDirective.prototype, "open", {
        get: function () {
            return this._open;
        },
        set: function (value) {
            this._open = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SidebarLinkDirective.prototype, "navCollapsedOpen", {
        get: function () {
            return this._navCollapsedOpen;
        },
        set: function (value) {
            this._navCollapsedOpen = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SidebarLinkDirective.prototype, "active", {
        get: function () {
            return this._active;
        },
        set: function (value) {
            this._active = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SidebarLinkDirective.prototype, "isShown", {
        get: function () {
            return this._isShown;
        },
        set: function (value) {
            this._isShown = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SidebarLinkDirective.prototype, "isHidden", {
        get: function () {
            return this._isHidden;
        },
        set: function (value) {
            this._isHidden = value;
        },
        enumerable: true,
        configurable: true
    });
    SidebarLinkDirective.prototype.ngOnInit = function () {
        this.sidebar.addLink(this);
    };
    SidebarLinkDirective.prototype.toggle = function () {
        this.sidebarList.activeLinks = [];
        this.sidebarList.setList(this.sidebar.getLinks());
        var classList = this.el.nativeElement.classList;
        if (this.level.toString().trim() === '1' && !classList.contains('has-sub')) {
            this.sidebarList.collapseOtherLinks(this);
        }
        if (this.level.toString().trim() === '2' || this.level.toString().trim() === '3') {
            this.sidebarList.toggleActiveList(this);
        }
        if (classList.contains('has-sub')) {
            if (classList.contains('open')) {
                this.sidebarList.collapse(this);
            }
            else {
                this.sidebarList.expand(this);
            }
        }
        else {
            this.active = true;
            this.sidebar.hideSidebar();
        }
    };
    return SidebarLinkDirective;
}());
export { SidebarLinkDirective };
