<ng-container [formGroup]="group">
  <div *ngIf="group.value[model.id] != null; else empty">
    <div class="d-flex flex-row align-items-baseline">
      <lib-numeric-condition-control
        [formControlName]="model.id"
        [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
        [model]="model"
        (blur)="onBlur($event)"
        (change)="onChange($event)"
        (focus)="onFocus($event)"></lib-numeric-condition-control>
      <button
        class="my-0 btn btn-warning mr-1 btn-fab btn-sm d-disabled-none"
        (click)="remove($event)">
        <i class="ft-minus-circle"></i>
        {{ 'APPLICATION.FORM.disable' | translate }}
      </button>
    </div>
  </div>
  <ng-template #empty>
    <button
      class="btn btn-primary mr-1 btn-fab btn-sm"
      (click)="add($event)">
      <i class="ft-plus-circle"></i>
      {{ 'APPLICATION.FORM.enable' | translate }}
    </button>
  </ng-template>
</ng-container>
