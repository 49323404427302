import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanLoad,
  UrlSegment,
  Route,
  CanActivateChild,
} from '@angular/router';
import { AuthStoreService } from './services/auth-store.service';
import { Observable } from 'rxjs';

@Injectable()
export class PfzGuard implements CanActivate, CanLoad, CanActivateChild {
  constructor(
    private router: Router,
    private authStore: AuthStoreService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    return this.getPfzGuardResult();
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    return this.getPfzGuardResult();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.getPfzGuardResult();
  }

  private getPfzGuardResult(): boolean {
    const claims = this.authStore.getUserClaims;

    const hasPFZClaim = claims.some(claim => claim.startsWith('Organization.Partners.PFZ'));
    if (!hasPFZClaim) {
      this.router.navigate(['organizations']);
    }

    return true;
  }
}
