<div
  class="container-fluid px-0"
  [appSpinner]="displaySpinner$ | async">
  <div class="row">
    <div
      class="col-12"
      *ngIf="productDelivery && formGroup && formModel$ | async as formModel">
      <div class="float-left product-delivery-checkbox">
        <input
          class="form-check-input"
          id="{{ controlPrefix }}-month-checkbox"
          type="checkbox"
          [checked]="isMonthSelected"
          (change)="onActive($event)" />
        <label
          class="form-check-label"
          for="{{ controlPrefix }}-month-checkbox">
          {{ month | enumTranslateKey: 'APPLICATION.DICTIONARIES.MONTH' | translate }}
        </label>
      </div>

      <form
        class="form-horizontal ng-dynamic-form product-delivery-form"
        [formGroup]="formGroup">
        <fieldset>
          <dynamic-ng-bootstrap-form
            class="row fieldAuditAreaFormRow"
            [group]="formGroup"
            [layout]="formLayout"
            [model]="formModel">
            <ng-template
              modelType="GROUP"
              align="START"
              let-legend="legend">
              <p
                *ngIf="legend"
                class="form-group-legend m-0 mb-1">
                {{ legend }}
              </p>
            </ng-template>
          </dynamic-ng-bootstrap-form>
        </fieldset>
      </form>
      <button
        type="button"
        class="btn btn-primary-outline btn-icon float-right product-delivery-button"
        (click)="onAddParcel()"
        [disabled]="!productDelivery.isActive">
        <i class="ph ph-plus"></i>
      </button>
    </div>
  </div>
</div>
