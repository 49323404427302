<ng-container [formGroup]="group">
  <ng-select
    #ngSelect
    [id]="elementId"
    [class.is-invalid]="showErrorMessages"
    formControlName="{{ model.id }}"
    [items]="model.items"
    [addTag]="model.addTag ? addTagFn : null"
    [addTagText]="'APPLICATION.FORM.multiSelect:addTagText' | translate"
    [bindLabel]="model.bindLabel"
    [groupBy]="model.groupBy"
    [multiple]="model.multiple"
    [clearable]="model.multiple"
    [closeOnSelect]="!model.multiple"
    [placeholder]="model.placeholder"
    [readonly]="model.readonly"
    [notFoundText]="'APPLICATION.FORM.multiSelect:notFoundText' | translate"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)"
    [searchFn]="model.searchByField != null ? customSearchFn : null">
    <ng-template
      ng-label-tmp
      let-item="item"
      *ngIf="model.translationPrefix == null">
      <span
        *ngIf="model.multiple"
        class="ng-value-icon left"
        (click)="unselect(item)"
        aria-hidden="true"
        >×</span
      >
      <span
        *ngIf="model.useTranslate"
        class="ng-value-label"
        >{{ item[model.bindLabel] | translate }}</span
      >
      <span
        *ngIf="model.useTranslate == false"
        class="ng-value-label"
        >{{ item[model.bindLabel] }}</span
      >
    </ng-template>

    <ng-template
      ng-optgroup-tmp
      let-item="item"
      let-index="index"
      *ngIf="model.translationPrefix == null">
      <span
        *ngIf="model.useTranslate"
        class="ng-value-label"
        >{{ item[model.groupBy] | translate }}</span
      >
      <span
        *ngIf="model.useTranslate == false"
        class="ng-value-label"
        >{{ item[model.groupBy] }}</span
      >
    </ng-template>

    <ng-template
      ng-option-tmp
      let-item="item"
      let-index="index"
      let-search="searchTerm"
      *ngIf="model.translationPrefix == null">
      <span
        *ngIf="model.useTranslate"
        class="ng-value-label"
        >{{ item[model.bindLabel] | translate }}</span
      >
      <span
        *ngIf="model.useTranslate == false"
        class="ng-value-label"
        >{{ item[model.bindLabel] }}</span
      >
    </ng-template>

    <ng-template
      ng-label-tmp
      let-item="item"
      *ngIf="model.translationPrefix != null">
      <span
        *ngIf="model.multiple"
        class="ng-value-icon left"
        (click)="unselect(item)"
        aria-hidden="true"
        >×</span
      >
      <span class="ng-value-label">{{ model.translationPrefix + '.' + item[model.bindLabel] | translate }}</span>
    </ng-template>

    <ng-template
      ng-optgroup-tmp
      let-item="item"
      let-index="index"
      *ngIf="model.translationPrefix != null">
      <span class="ng-value-label">{{ model.translationPrefix + '.' + item[model.groupBy] | translate }}</span>
    </ng-template>

    <ng-template
      ng-option-tmp
      let-item="item"
      let-index="index"
      let-search="searchTerm"
      *ngIf="model.translationPrefix != null">
      <span class="ng-value-label">{{ model.translationPrefix + '.' + item[model.bindLabel] | translate }}</span>
    </ng-template>
  </ng-select>
</ng-container>
