/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-unread-notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "ngx-perfect-scrollbar";
import * as i6 from "crmcloud-core";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "./message-unread-notification.component";
import * as i9 from "../common/services/message-unread.service";
var styles_MessageUnreadNotificationComponent = [i0.styles];
var RenderType_MessageUnreadNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageUnreadNotificationComponent, data: {} });
export { RenderType_MessageUnreadNotificationComponent as RenderType_MessageUnreadNotificationComponent };
function View_MessageUnreadNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "notification badge badge-pill badge-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.ngIf; _ck(_v, 1, 0, currVal_0); }); }
function View_MessageUnreadNotificationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "a", [["class", "dropdown-item noti-container py-3 border-bottom border-bottom-blue-grey border-bottom-lighten-4"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 3, "i", [["class", "float-left d-block font-large-1 mt-1 mr-2"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 6, "span", [["class", "noti-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "noti-title line-height-1 d-block text-bold-400 info"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [["class", "noti-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵppd(13, 2)], function (_ck, _v) { var currVal_2 = _ck(_v, 2, 0, "/messages", _v.context.$implicit.id); _ck(_v, 1, 0, currVal_2); var currVal_3 = "float-left d-block font-large-1 mt-1 mr-2"; var currVal_4 = i1.ɵunv(_v, 5, 1, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 0), ((_v.context.$implicit == null) ? null : _v.context.$implicit.type))); _ck(_v, 5, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent, 1), _v.context.$implicit.title, 20)); _ck(_v, 9, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent.parent, 1), _v.context.$implicit.body, 50)); _ck(_v, 12, 0, currVal_6); }); }
function View_MessageUnreadNotificationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "h-100 d-flex justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "align-self-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("MESSAGE.NAVBAR.list:empty")); _ck(_v, 2, 0, currVal_0); }); }
function View_MessageUnreadNotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "noti-list"]], null, null, null, null, null)), i1.ɵdid(1, 999424, null, 0, i5.PerfectScrollbarDirective, [i1.NgZone, i1.KeyValueDiffers, i1.ElementRef, i1.PLATFORM_ID, [2, i5.PERFECT_SCROLLBAR_CONFIG]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageUnreadNotificationComponent_3)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageUnreadNotificationComponent_4)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _v.context.ngIf; var currVal_1 = _co.trackByFn; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.ngIf.length === 0); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_MessageUnreadNotificationComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i6.MessageTypeIconPipe, []), i1.ɵpid(0, i6.HtmlTextShortPipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 22, "div", [["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(3, 1720320, null, 3, i7.NgbDropdown, [i1.ChangeDetectorRef, i7.NgbDropdownConfig, i3.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i7.NgbNavbar]], { placement: [0, "placement"] }, null), i1.ɵqud(603979776, 1, { _menu: 0 }), i1.ɵqud(603979776, 2, { _menuElement: 0 }), i1.ɵqud(603979776, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 7, "a", [["aria-haspopup", "true"], ["class", "nav-link position-relative dropdown-toggle"], ["id", "dropdownBasic2"], ["ngbDropdownToggle", ""]], [[8, "title", 0], [1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 8).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i7.NgbDropdownToggle, [i7.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i7.NgbDropdownAnchor, null, [i7.NgbDropdownToggle]), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "icon-bell ph-duotone ph-envelope"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MessageUnreadNotificationComponent_1)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, [[2, 0]], null, 9, "div", [["aria-labelledby", "dropdownBasic2"], ["class", "notification-dropdown"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 16).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, [[1, 4]], 1, i7.NgbDropdownMenu, [i7.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MessageUnreadNotificationComponent_2)), i1.ɵdid(19, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(21, 0, null, null, 3, "a", [["class", "noti-footer primary text-center d-block border-top border-top-blue-grey border-top-lighten-4 text-bold-400 py-1"], ["routerLink", "/messages"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(23, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "bottom-right"; _ck(_v, 3, 0, currVal_1); var currVal_4 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.unreadMessageCount$)); _ck(_v, 13, 0, currVal_4); var currVal_8 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform(_co.unreadMessages$)); _ck(_v, 19, 0, currVal_8); var currVal_11 = "/messages"; _ck(_v, 22, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).isOpen(); _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 10).transform("MESSAGE.list:header")), ""); var currVal_3 = i1.ɵnov(_v, 8).dropdown.isOpen(); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_5 = true; var currVal_6 = i1.ɵnov(_v, 16).dropdown.isOpen(); var currVal_7 = i1.ɵnov(_v, 16).placement; _ck(_v, 15, 0, currVal_5, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 22).target; var currVal_10 = i1.ɵnov(_v, 22).href; _ck(_v, 21, 0, currVal_9, currVal_10); var currVal_12 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("MESSAGE.NAVBAR.viewAll")); _ck(_v, 23, 0, currVal_12); }); }
export function View_MessageUnreadNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-unread-notification", [], null, null, null, View_MessageUnreadNotificationComponent_0, RenderType_MessageUnreadNotificationComponent)), i1.ɵdid(1, 114688, null, 0, i8.MessageUnreadNotificationComponent, [i9.MESSAGE_UNREAD_SERVICE], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageUnreadNotificationComponentNgFactory = i1.ɵccf("app-message-unread-notification", i8.MessageUnreadNotificationComponent, View_MessageUnreadNotificationComponent_Host_0, {}, {}, []);
export { MessageUnreadNotificationComponentNgFactory as MessageUnreadNotificationComponentNgFactory };
