import { OnInit, OnDestroy, AfterViewInit, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ConfigService } from '@crm-portal/core/layout/sidebar/services/config.service';
import { LayoutService } from '@crm-portal/core/layout/sidebar/services/layout.service';
var SidebarComponent = /** @class */ (function () {
    function SidebarComponent(translate, configService, layoutService) {
        var _this = this;
        this.translate = translate;
        this.configService = configService;
        this.logoutEmit = new EventEmitter();
        this.activeTitles = [];
        this.config = {};
        this.destroy$ = new Subject();
        if (this.depth === undefined) {
            this.depth = 0;
            this.expanded = true;
        }
        this.layoutSub = layoutService.customizerChangeEmitted$.subscribe(function (options) {
            if (options) {
                if (options.compactMenu === true) {
                    _this.expanded = false;
                }
                else if (options.compactMenu === false) {
                    _this.expanded = true;
                }
            }
        });
    }
    SidebarComponent.prototype.ngOnInit = function () {
        this.config = this.configService.templateConf;
    };
    SidebarComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            // tslint:disable-next-line: triple-equals
            if (_this.config.layout.sidebar.collapsed != undefined) {
                if (_this.config.layout.sidebar.collapsed === true) {
                    _this.expanded = false;
                }
                else if (_this.config.layout.sidebar.collapsed === false) {
                    _this.expanded = true;
                }
            }
        }, 0);
    };
    SidebarComponent.prototype.ngOnDestroy = function () {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
        this.destroy$.next();
        this.destroy$.complete();
    };
    SidebarComponent.prototype.logout = function () {
        this.logoutEmit.emit();
    };
    SidebarComponent.prototype.toggleSlideInOut = function () {
        this.expanded = !this.expanded;
    };
    SidebarComponent.prototype.handleToggle = function (titles) {
        this.activeTitles = titles;
    };
    SidebarComponent.prototype.hasSubmenuAndHasNotExternalLink = function (item) {
        return item.submenu.length > 0 && !item.isExternalLink && this.isMenuItemForComponent(item);
    };
    SidebarComponent.prototype.hasNotSubmenuAndExternalLink = function (item) {
        return item.submenu.length === 0 && !item.isExternalLink && this.isMenuItemForComponent(item);
    };
    SidebarComponent.prototype.hasMenuExternalLink = function (item) {
        return item.isExternalLink && this.isMenuItemForComponent(item);
    };
    SidebarComponent.prototype.isMenuItemForComponent = function (item) {
        var _this = this;
        if (item.components === undefined) {
            return true;
        }
        if (item.components.length === 0) {
            return true;
        }
        if (this.userClaims === undefined) {
            return true;
        }
        return item.components.some(function (component) {
            return _this.userClaims.some(function (claim) {
                return claim.startsWith(component);
            });
        });
    };
    SidebarComponent.prototype.isMenuGroupForComponent = function (item) {
        var _this = this;
        if (item === undefined) {
            return true;
        }
        if (item.components === undefined) {
            return true;
        }
        if (item.components.length === 0) {
            return true;
        }
        if (this.userClaims === undefined) {
            return true;
        }
        return item.components.some(function (component) {
            return _this.userClaims.some(function (claim) {
                return claim.startsWith(component);
            });
        });
    };
    return SidebarComponent;
}());
export { SidebarComponent };
