export const AGRI_CRM_TOKEN = 'agricrm';

export interface UserAuth {
  email: string;
  password: string;
}

export type UserLogin = Pick<UserAuth, 'email' | 'password'> & {
  host: 'agricrm';
};

export interface UserAuthDto {
  id: string;
  userId: string;
  password: string;
  email: string;
  isActive: boolean;
  isDeleted: boolean;
  refreshToken: string;
  refreshTokenExpiryTime: Date;
}

export interface AuthenticateResultDto {
  accessToken: string;
  encryptedAccessToken: string;
  refreshToken: string;
  expireInSeconds: number;
  userId: number;
}

export type UserForgottenPassword = Pick<UserAuth, 'email'>;
