import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { AuthStorageService } from './auth-storage.service';
import { StorageKey } from '../models/storage-key.enum';
import { map, switchMap, tap } from 'rxjs/operators';
import { UserProfileDto } from '@crm-portal/core/auth/models/user-profile.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthStoreService {
  private userLogged = new BehaviorSubject<boolean>(false);
  private userClaims = new BehaviorSubject<string[]>(null);
  private userId = new BehaviorSubject<string>(null);
  private userName = new BehaviorSubject<string>(null);
  private userOrganizationId = new BehaviorSubject<string>(null);
  public userOrganizationId$ = this.userOrganizationId.asObservable();
  private userOrganizationName = new BehaviorSubject<string>(null);

  get getUserClaims(): string[] {
    if (this.userClaims && this.userClaims.value != null) {
      return this.userClaims.value;
    }

    return [];
  }

  get getUserOrganizationId(): string {
    if (this.userOrganizationId && this.userOrganizationId.value != null) {
      return this.userOrganizationId.value;
    }

    return;
  }

  get getUserOrganizationName(): string {
    if (this.userOrganizationName && this.userOrganizationName.value != null) {
      return this.userOrganizationName.value;
    }

    return;
  }

  get getUserFullname(): string {
    if (this.userName && this.userName.value != null) {
      return this.userName.value;
    }

    return;
  }

  get getUserId(): string {
    if (this.userId && this.userId.value != null) {
      return this.userId.value;
    }

    return;
  }

  get isAuthenticated(): boolean {
    return this.userLogged.value;
  }

  get getAccessToken(): string {
    return this.storageService.getItemSync<string>(StorageKey.ACCESS_TOKEN_KEY);
  }

  get getRefreshToken(): string {
    return this.storageService.getItemSync<string>(StorageKey.REFRESH_TOKEN_KEY);
  }

  constructor(private storageService: AuthStorageService) {
    this.initUserState();
  }

  private initUserState() {
    forkJoin({
      accessToken: this.storageService.getItem<string>(StorageKey.ACCESS_TOKEN_KEY),
      userProfile: this.storageService.getItem<UserProfileDto>(StorageKey.USER_PROFILE),
    }).subscribe(
      res => {
        const tokenIsValid = res.accessToken !== null && res.accessToken !== undefined && res.accessToken !== '';
        const userProfileExists = res.userProfile !== null;
        if (tokenIsValid && userProfileExists) {
          this.userLogged.next(true);
          this.updateUserDataInternal(res.userProfile);
        }
      },
      () => {
        this.updateUserNotLogged();
      },
    );
  }

  public updateAuthTokens(accessToken: string, refreshToken: string): Observable<void> {
    const accessTokenObs = this.storageService.setItem(StorageKey.ACCESS_TOKEN_KEY, accessToken);
    const refreshTokenObs = this.storageService.setItem(StorageKey.REFRESH_TOKEN_KEY, refreshToken);
    return forkJoin([accessTokenObs, refreshTokenObs]).pipe(
      tap(() => this.userLogged.next(accessToken !== null && refreshToken !== null)),
      switchMap(() => of(null)),
    );
  }

  public updateUserData(userProfile: UserProfileDto): Observable<void> {
    return this.storageService.setItem(StorageKey.USER_PROFILE, userProfile).pipe(
      tap(() => {
        this.updateUserDataInternal(userProfile);
      }),
      map(() => null),
    );
  }

  private updateUserDataInternal(userProfile: UserProfileDto): void {
    this.userClaims.next(userProfile.permissions);
    this.userOrganizationId.next(userProfile.organizationId);
    this.userOrganizationName.next(userProfile.organizationName);
    this.userId.next(userProfile.userId);
    this.userName.next(userProfile.fullName);
  }

  public updateUserNotLogged(): void {
    this.updateAuthTokens(null, null);
    this.updateUserData(null);
  }
}
