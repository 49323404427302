import { Component, OnInit, Input, OnDestroy, AfterViewInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { RouteInfo, RouteGroup } from './sidebar.metadata';
import { customAnimations } from '@crm-portal/core/layout/animations/custom-animations';
import { ConfigService } from '@crm-portal/core/layout/sidebar/services/config.service';
import { LayoutService } from '@crm-portal/core/layout/sidebar/services/layout.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: customAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() logoutEmit = new EventEmitter<void>();

  @Input() public menuGroups: RouteGroup[];
  @Input() public version: string;
  @Input() public userClaims: string[];

  @Input() public userData: {
    name: string;
    organizationName: string;
  };

  depth: number;
  activeTitles: string[] = [];
  expanded: boolean;

  @Input() logoUrl: string;
  public config: any = {};
  layoutSub: Subscription;

  private destroy$ = new Subject<void>();

  constructor(
    public readonly translate: TranslateService,
    private readonly configService: ConfigService,
    layoutService: LayoutService,
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }

    this.layoutSub = layoutService.customizerChangeEmitted$.subscribe(options => {
      if (options) {
        if (options.compactMenu === true) {
          this.expanded = false;
        } else if (options.compactMenu === false) {
          this.expanded = true;
        }
      }
    });
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // tslint:disable-next-line: triple-equals
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
        } else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
        }
      }
    }, 0);
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout(): void {
    this.logoutEmit.emit();
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  hasSubmenuAndHasNotExternalLink(item: RouteInfo): boolean {
    return item.submenu.length > 0 && !item.isExternalLink && this.isMenuItemForComponent(item);
  }

  hasNotSubmenuAndExternalLink(item: RouteInfo): boolean {
    return item.submenu.length === 0 && !item.isExternalLink && this.isMenuItemForComponent(item);
  }

  hasMenuExternalLink(item: RouteInfo): boolean {
    return item.isExternalLink && this.isMenuItemForComponent(item);
  }

  isMenuItemForComponent(item: RouteInfo): boolean {
    if (item.components === undefined) {
      return true;
    }

    if (item.components.length === 0) {
      return true;
    }

    if (this.userClaims === undefined) {
      return true;
    }

    return item.components.some(component => {
      return this.userClaims.some(claim => {
        return claim.startsWith(component);
      });
    });
  }

  isMenuGroupForComponent(item: RouteGroup): boolean {
    if (item === undefined) {
      return true;
    }

    if (item.components === undefined) {
      return true;
    }

    if (item.components.length === 0) {
      return true;
    }

    if (this.userClaims === undefined) {
      return true;
    }

    return item.components.some(component => {
      return this.userClaims.some(claim => {
        return claim.startsWith(component);
      });
    });
  }
}
