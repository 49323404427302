export enum SiteTitle {
  carbonExchange = 'Giełda emisji CO₂',
  contractsList = 'Umowy - lista',
  contractsReportsSuppliers = 'Umowy - raport dostawcy',
  contractsReportProductsCatalogs = 'Umowy - raport katalogu',
  contractsReportDeliveries = 'Umowy - raport dostaw',
  organizations = 'Gospodarstwa',
  contacts = 'Kontakty',
  users = 'Organizacja',
  cultivationAssistant = 'Asystent upraw',
  soilAnalysis = 'Badania gleby',
  suppliers = 'Kontrahenci',
  notes = 'Notatki',
  purchasePlaces = 'Skupy',
  purchasePlacesQuote = 'Notowania',
  support = 'Pomoc',
  pesticides = 'Środki ochrony roślin',
  rucip = 'Rucip',
  seeds = 'Nasiona',
  fertilizersDb = 'Baza nawozów',
  calalogDefinitions = 'Katalog produktów',
  fertilizerStockNeeds = 'Potrzeby nawozowe',
  login = 'Logowanie',
  register = 'Rejestracja',
  forgottenPassword = 'Przypomnienie hasła',
  resetPassword = 'Zresetuj hasło',
  confirmEmail = 'Potwierdzanie email',
  activationLink = 'Link aktywacyjny',
  acceptInvitation = 'Akceptacja zaproszenia',
}
