<div class="modal-header">
  <h4
    class="modal-title"
    id="modal-title">
    {{ titleTranslateKey | translate }}
  </h4>
  <button
    type="button"
    class="close"
    aria-describedby="modal-title"
    (click)="modal.dismiss(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p [innerHTML]="messageTranslateKey | translate | format: values"></p>
</div>
<div class="modal-footer gap-16">
  <button
    type="button"
    class="btn btn-secondary"
    (click)="modal.dismiss(false)">
    {{ 'APPLICATION.MODAL.cancel' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="modal.close(true)">
    {{ 'APPLICATION.MODAL.confirm' | translate }}
  </button>
</div>
