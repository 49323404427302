<ng-container [formGroup]="group">
  <lib-file-sharing-form-control
    [formControlName]="model.id"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    [translateKey]="model.translateKey"
    [accept]="model.accept"
    [maxSize]="model.maxSize"
    [multiple]="model.multiple"
    [readonly]="model.readonly"
    [touched]="control.touched"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)"></lib-file-sharing-form-control>
</ng-container>
