<ng-container [formGroup]="group">
  <lib-commissioned-audit-result-form-control
    formControlName="{{ model.id }}"
    [translateKey]="model.translateKey"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    [touched]="control.touched"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)">
  </lib-commissioned-audit-result-form-control>
</ng-container>
