import { isPlatformBrowser } from '@angular/common';
import { Observable, of } from 'rxjs';
import * as i0 from "@angular/core";
var AuthStorageService = /** @class */ (function () {
    function AuthStorageService(platformId) {
        this.platformId = platformId;
        this.prefix = 'farmcloud_';
    }
    Object.defineProperty(AuthStorageService.prototype, "size", {
        get: function () {
            return of(localStorage.length);
        },
        enumerable: true,
        configurable: true
    });
    AuthStorageService.prototype.getItem = function (key) {
        var data = this.getItemSync(key);
        return of(data);
    };
    AuthStorageService.prototype.getItemSync = function (key) {
        if (!isPlatformBrowser(this.platformId)) {
            return null;
        }
        var unparsedData = localStorage.getItem("" + this.prefix + key);
        if (unparsedData == null) {
            return null;
        }
        return JSON.parse(unparsedData);
    };
    AuthStorageService.prototype.setItem = function (key, data) {
        return of(this.setItemSync(key, data));
    };
    AuthStorageService.prototype.setItemSync = function (key, data) {
        var internalKey = "" + this.prefix + key;
        if (data === undefined || data === null) {
            localStorage.removeItem(internalKey);
            return data;
        }
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem(internalKey, JSON.stringify(data));
        }
        return data;
    };
    AuthStorageService.prototype.removeItem = function (key) {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem("" + this.prefix + key);
        }
        return of(true);
    };
    AuthStorageService.prototype.clearAll = function () {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.clear();
        }
        return of(true);
    };
    AuthStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthStorageService_Factory() { return new AuthStorageService(i0.ɵɵinject(i0.PLATFORM_ID)); }, token: AuthStorageService, providedIn: "root" });
    return AuthStorageService;
}());
export { AuthStorageService };
