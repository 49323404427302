import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigurationService, NotificationService } from 'crmcloud-core';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AuthProxyService } from './auth-proxy.service';
import { AuthStoreService } from '@crm-portal/core/auth/services/auth-store.service';
import { RefreshTokenInputDto } from '@crm-portal/core/auth/models/refresh-token-input.dto';
const AGRICRM_TOKEN = 'agricrm';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  private AUTH_HEADER = 'Authorization';
  private refreshInProgress: boolean = false;
  private refreshSubject: ReplaySubject<string>;
  private static readonly SecondsToMsRation = 1000;

  constructor(
    private authProxyService: AuthProxyService,
    private authStoreService: AuthStoreService,
    private config: AppConfigurationService,
    private router: Router,
    private notificationService: NotificationService,
  ) {}
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes(this.config.configuration.api_url)) {
      return next.handle(req);
    }

    const token = this.authStoreService.getAccessToken;
    if (!token) {
      return next.handle(req);
    }

    if (this.isJwtInvalid(token)) {
      return this.refreshAccessToken().pipe(
        switchMap((newToken: string) => {
          return next.handle(this.addToken(req, newToken));
        }),
      );
    }

    const request = this.addToken(req, token);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Request is unauthorized - logout
        if (error && error.status === 401) {
          this.handleSessionExpired();
          return of(null);
        }

        return throwError(error);
      }),
    );
  }

  private addToken(request: HttpRequest<unknown>, token: string) {
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, `Bearer ${token}`),
    });
  }

  private isJwtInvalid(token: string): boolean {
    const tokenExpirationDate = this.getTokenExpirationDate(token);
    const currentDate = new Date().getTime() / AuthInterceptorService.SecondsToMsRation;
    return currentDate >= tokenExpirationDate;
  }

  private getTokenExpirationDate(token: string): number {
    const decoded = jwtDecode(token);
    if (decoded.exp === undefined) {
      return null;
    }
    return decoded.exp;
  }

  private refreshAccessToken(): Observable<string> {
    if (this.refreshInProgress) {
      return this.refreshSubject;
    }

    const accessToken = this.authStoreService.getAccessToken;
    const refreshToken = this.authStoreService.getRefreshToken;
    const refreshTokenInput = {
      accessToken,
      refreshToken,
      host: AGRICRM_TOKEN,
    } as RefreshTokenInputDto;

    this.refreshSubject = new ReplaySubject<string>();
    this.refreshInProgress = true;
    return this.authProxyService.refreshToken(refreshTokenInput).pipe(
      mergeMap(data => {
        return this.authStoreService.updateAuthTokens(data.accessToken, data.refreshToken).pipe(
          map(() => {
            this.refreshSubject.next(data.accessToken);
            this.refreshSubject.complete();
            this.refreshInProgress = false;
            return data.accessToken;
          }),
        );
      }),
      catchError(err => {
        console.error(err);
        this.refreshInProgress = false;
        this.handleSessionExpired();
        return of(err);
      }),
    );
  }

  private handleSessionExpired() {
    this.authStoreService.updateUserNotLogged();
    return this.router.navigate(['auth', 'login']).then(() => {
      this.notificationService.info(`AUTH.logoutError:message`);
    });
  }
}
export let AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptorService,
  multi: true,
};
