import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from 'crmcloud-core';
import { Observable } from 'rxjs';
import { RoutePermissionsDto } from './models/route-permissions.dto';
import { AuthStoreService } from './services/auth-store.service';

@Injectable()
export class IdpOrganizationGuard implements CanActivate, CanActivateChild {
  constructor(
    private authStore: AuthStoreService,
    private logger: LoggerService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    // use separate interface, do not reuse CoponentClaimsDto
    const requiredPermission = route.data as RoutePermissionsDto;
    return this.checkPermissions(requiredPermission);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const requiredPermission = childRoute.data as RoutePermissionsDto;
    return this.checkPermissions(requiredPermission);
  }

  private checkPermissions(requiredPermission: RoutePermissionsDto): boolean {
    if (!requiredPermission.componentKey) {
      // if there are no requirements defined
      // assume that the route can be freely accessed
      return true;
    }

    const userClaims = this.authStore.getUserClaims;

    if (!userClaims || userClaims.length == 0) {
      const message = '[IdpOrganizationGuard] No groups defined in user claims.';
      this.logger.trackException(new Error(message));
      return false;
    }

    return requiredPermission.permissions.some(c => {
      const fullPermission = requiredPermission.componentKey + '.' + c;
      return userClaims.some(c => c === fullPermission);
    });
  }
}
