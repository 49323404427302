import { Router } from '@angular/router';
import { AuthStoreService } from './services/auth-store.service';
var IdpAuthGuard = /** @class */ (function () {
    function IdpAuthGuard(router, authStore) {
        this.router = router;
        this.authStore = authStore;
    }
    IdpAuthGuard.prototype.canActivate = function (route, state) {
        return this.getAuthGuardResult();
    };
    IdpAuthGuard.prototype.canLoad = function (route, segments) {
        return this.getAuthGuardResult();
    };
    IdpAuthGuard.prototype.getAuthGuardResult = function () {
        var isUserLoggedIn = this.authStore.isAuthenticated;
        if (!isUserLoggedIn) {
            this.router.navigate(['auth/login']);
        }
        return isUserLoggedIn;
    };
    return IdpAuthGuard;
}());
export { IdpAuthGuard };
