<agm-data-layer
  [geoJson]="geoJsonUrl"
  [style]="styleFunc"
  (layerClick)="onLayerClick($event)">
</agm-data-layer>

<agm-snazzy-info-window
  *ngIf="feature && showInfoWindow"
  [maxWidth]="320"
  [closeWhenOthersOpen]="true"
  [latitude]="latLng.lat()"
  [longitude]="latLng.lng()"
  [isOpen]="showInfoWindow"
  (afterClose)="closeInfoWindow()">
  <ng-template>
    <div class="row no-gutters">
      <div class="col-6">{{ feature.l.area }} [ha]</div>
      <div class="col-6">
        {{ feature.l.name }}
      </div>
    </div>
  </ng-template>
</agm-snazzy-info-window>
