import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent(translate) {
        this.translate = translate;
        this.isCollapsed = true;
        this.toggleHideSidebar = new EventEmitter();
    }
    NavbarComponent.prototype.toggleSidebar = function () {
        var appSidebar = document.getElementsByClassName('app-sidebar')[0];
        if (appSidebar.classList.contains('hide-sidebar')) {
            this.toggleHideSidebar.emit(false);
        }
        else {
            this.toggleHideSidebar.emit(true);
        }
    };
    return NavbarComponent;
}());
export { NavbarComponent };
