import { ChangeDetectorRef, Component } from '@angular/core';
import { ROUTES } from '@crm-portal/app-routing.config';
import { AuthLoginType, LOGIN_TYPE_STORAGE_KEY } from '@crm-portal/core/auth/models/auth-login-type.enum';
import { AuthStorageService } from '@crm-portal/core/auth/services/auth-storage.service';
import { AuthStoreService } from '@crm-portal/core/auth/services/auth-store.service';
import { AuthService } from '@crm-portal/core/auth/services/auth.service';
import { MESSAGE_UNREAD_SERVICE } from '@crm-portal/core/layout/navbar/message-unread-notification/common/services/message-unread.service';
import { RouteInfo, RouteGroup } from '@crm-portal/core/layout/sidebar/sidebar.metadata';
import { MessageCoreNotificationService } from '@crm-portal/core/message/services/message-core-notification.service';
import { FeatureConfiguration, FeatureService } from 'crmcloud-core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
  providers: [
    {
      provide: MESSAGE_UNREAD_SERVICE,
      useClass: MessageCoreNotificationService,
    },
  ],
})
export class FullLayoutComponent {
  hideSidebar: boolean;
  iscollapsed = false;

  public loginTypeEnum = AuthLoginType;

  constructor(
    private readonly authService: AuthService,
    private readonly authStore: AuthStoreService,
    private readonly authStorageService: AuthStorageService,
    private readonly featureService: FeatureService,
    private readonly changeDetector: ChangeDetectorRef,
  ) {
    this.routes = this.featureService.loadFeatures().pipe(
      map(config => {
        return this.routeFilter(ROUTES, config);
      }),
    );
  }

  public routes: Observable<RouteGroup[]>;
  get groupClaims(): string[] {
    return this.authStore.getUserClaims;
  }

  get loginType(): Observable<string> {
    return this.authStorageService.getItem<string>(LOGIN_TYPE_STORAGE_KEY);
  }

  get user(): { name: string; organizationName: string } {
    return {
      name: this.authStore.getUserFullname,
      organizationName: this.authStore.getUserOrganizationName,
    };
  }

  toggleHideSidebar($event: boolean): void {
    setTimeout(() => {
      this.hideSidebar = $event;
      this.changeDetector.detectChanges();
    }, 0);
  }

  logout() {
    this.authService.logout().pipe(take(1)).subscribe();
  }

  hasAccess(componentKey: string): boolean {
    return this.groupClaims.some(claim => {
      return claim.startsWith(componentKey);
    });
  }

  private routeFilter(routeGroups: RouteGroup[], featureConfig: FeatureConfiguration): RouteGroup[] {
    const output: RouteGroup[] = [];

    routeGroups.forEach(g => {
      const routes = this.recursiveRouteFilter(g.routeGroup, featureConfig);
      output.push({ ...g, routeGroup: routes });
    });

    return output;
  }

  private recursiveRouteFilter(routes: RouteInfo[], featureConfig: FeatureConfiguration): RouteInfo[] {
    const result = [];
    routes.forEach(route => {
      if (featureConfig.isEnabled(route.title)) {
        if (route.submenu && route.submenu.length) {
          route.submenu = this.recursiveRouteFilter(route.submenu, featureConfig);
        }
        result.push(route);
      }
    });
    return result;
  }
}
