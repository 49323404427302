import { OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ActivationStart, Router } from '@angular/router';
import { AppConfigurationService, SupportedLanguages, TranslateModuleOptions, initAppConfiguration, } from 'crmcloud-core';
import { TranslationInitializationService } from './translation-initialization.service';
var DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true,
    wheelPropagation: false,
};
var DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG = {
    defaultLanguage: SupportedLanguages.PL,
};
export function initializeTranslations(translationInitService) {
    return function () {
        return translationInitService.initializeTranslations();
    };
}
var ɵ0 = initAppConfiguration, ɵ1 = DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG, ɵ2 = DEFAULT_PERFECT_SCROLLBAR_CONFIG, ɵ3 = function (window, appConfigurationService) { return ({
    auth: {
        clientId: appConfigurationService.configuration.ad_clientID,
        authority: appConfigurationService.configuration.ad_authority,
        validateAuthority: true,
        redirectUri: window.location.origin + '/',
        navigateToLoginRequestUrl: true,
    },
}); }, ɵ4 = function (appConfigurationService) { return ({
    consentScopes: [appConfigurationService.configuration.ad_clientID],
    popUp: false,
    protectedResourceMap: [[appConfigurationService.configuration.api_url, [appConfigurationService.configuration.ad_clientID]]],
}); };
var AppModule = /** @class */ (function () {
    function AppModule(router, titleService) {
        var _this = this;
        this.router = router;
        this.titleService = titleService;
        //set title of each route that has data title provided
        this.routerSub = this.router.events.subscribe(function (data) {
            if (data instanceof ActivationStart && data.snapshot.data['title']) {
                var title = data.snapshot.data['title'];
                _this.titleService.setTitle(title + " - FoodPass");
            }
        });
    }
    AppModule.prototype.ngOnDestroy = function () {
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
    };
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
