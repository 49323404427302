<div class="image-container">
  <div class="match-parent">
    <div
      class="not-selected"
      [ngClass]="{ over: isDraggingOverDropZone }"
      *ngIf="!isFile">
      <button
        type="button"
        class="add-image-btn"
        (click)="onImageClicked()">
        <div>
          <div class="d-flex align-items-center justify-content-center">
            <span [ngClass]="{ required: isRequired }">{{ label | translate }}</span>
            <i
              class="file-form-control__add-icon ml-1 ph ph-plus"
              [ngClass]="readonly ? 'ph ph-image' : 'ph ph-plus'"></i>
          </div>
          <span
            *ngIf="errorMessageTranslationKey"
            class="text-danger font-sm"
            >{{ errorMessageTranslationKey | translate }}{{ errorMessageInfo }}</span
          >
        </div>
      </button>
    </div>
    <div
      class="selected-status-wrapper"
      *ngIf="isFile">
      <div class="hide">
        <div class="buttons-wrapper">
          <div
            tabindex="0"
            class="btn btn-action btn-icon btn-round"
            *ngIf="!noImageFile"
            (click)="openLightbox()"
            role="button">
            <i class="ph ph-magnifying-glass-plus"></i>
          </div>
          <div
            tabindex="0"
            class="btn btn-action btn-icon btn-round"
            *ngIf="this._imageFile.link"
            (click)="downloadFile()"
            role="button">
            <i class="ph ph-download-simple"></i>
          </div>
          <button
            type="button"
            class="btn btn-action btn-icon btn-round"
            (click)="removeImage()"
            *ngIf="!readonly"
            [disabled]="isDisabled">
            <i class="ph ph-trash color-danger"></i>
          </button>
        </div>
      </div>
      <img
        [src]="imageThumbnail"
        #imageElement />
      <span
        *ngIf="noImageFile"
        class="no-image-file-span"
        >{{ imageName.length > 15 ? (imageName | slice: 0 : 15) + '...' : imageName }}</span
      >
    </div>
  </div>
  <fieldset [disabled]="isDisabled">
    <input
      type="file"
      #fileInput
      (change)="onFileChanged()" />
  </fieldset>
  <div
    class="drag-overlay"
    [hidden]="true"
    #dragOverlay></div>
</div>
<app-validation-message
  [field]="formControl"
  [displayName]="label | translate"
  [isFile]="true"></app-validation-message>
