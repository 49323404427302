import * as tslib_1 from "tslib";
import { ChangeDetectorRef } from '@angular/core';
import { ROUTES } from '@crm-portal/app-routing.config';
import { AuthLoginType, LOGIN_TYPE_STORAGE_KEY } from '@crm-portal/core/auth/models/auth-login-type.enum';
import { AuthStorageService } from '@crm-portal/core/auth/services/auth-storage.service';
import { AuthStoreService } from '@crm-portal/core/auth/services/auth-store.service';
import { AuthService } from '@crm-portal/core/auth/services/auth.service';
import { FeatureService } from 'crmcloud-core';
import { map, take } from 'rxjs/operators';
var FullLayoutComponent = /** @class */ (function () {
    function FullLayoutComponent(authService, authStore, authStorageService, featureService, changeDetector) {
        var _this = this;
        this.authService = authService;
        this.authStore = authStore;
        this.authStorageService = authStorageService;
        this.featureService = featureService;
        this.changeDetector = changeDetector;
        this.iscollapsed = false;
        this.loginTypeEnum = AuthLoginType;
        this.routes = this.featureService.loadFeatures().pipe(map(function (config) {
            return _this.routeFilter(ROUTES, config);
        }));
    }
    Object.defineProperty(FullLayoutComponent.prototype, "groupClaims", {
        get: function () {
            return this.authStore.getUserClaims;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FullLayoutComponent.prototype, "loginType", {
        get: function () {
            return this.authStorageService.getItem(LOGIN_TYPE_STORAGE_KEY);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FullLayoutComponent.prototype, "user", {
        get: function () {
            return {
                name: this.authStore.getUserFullname,
                organizationName: this.authStore.getUserOrganizationName,
            };
        },
        enumerable: true,
        configurable: true
    });
    FullLayoutComponent.prototype.toggleHideSidebar = function ($event) {
        var _this = this;
        setTimeout(function () {
            _this.hideSidebar = $event;
            _this.changeDetector.detectChanges();
        }, 0);
    };
    FullLayoutComponent.prototype.logout = function () {
        this.authService.logout().pipe(take(1)).subscribe();
    };
    FullLayoutComponent.prototype.hasAccess = function (componentKey) {
        return this.groupClaims.some(function (claim) {
            return claim.startsWith(componentKey);
        });
    };
    FullLayoutComponent.prototype.routeFilter = function (routeGroups, featureConfig) {
        var _this = this;
        var output = [];
        routeGroups.forEach(function (g) {
            var routes = _this.recursiveRouteFilter(g.routeGroup, featureConfig);
            output.push(tslib_1.__assign({}, g, { routeGroup: routes }));
        });
        return output;
    };
    FullLayoutComponent.prototype.recursiveRouteFilter = function (routes, featureConfig) {
        var _this = this;
        var result = [];
        routes.forEach(function (route) {
            if (featureConfig.isEnabled(route.title)) {
                if (route.submenu && route.submenu.length) {
                    route.submenu = _this.recursiveRouteFilter(route.submenu, featureConfig);
                }
                result.push(route);
            }
        });
        return result;
    };
    return FullLayoutComponent;
}());
export { FullLayoutComponent };
