<div
  class="container-fluid px-0"
  *ngIf="formModel$ | async as formModelx">
  <form
    class="form-horizontal ng-dynamic-form"
    [formGroup]="formGroup">
    <fieldset>
      <dynamic-ng-bootstrap-form
        class="row no-gutters"
        [group]="formGroup"
        [layout]="formLayout"
        [model]="formModelx">
        <ng-template
          modelType="GROUP"
          align="START"
          let-legend="legend">
          <p
            *ngIf="legend"
            class="form-group-legend m-0 mb-1">
            {{ legend }}
          </p>
        </ng-template>
      </dynamic-ng-bootstrap-form>
    </fieldset>
  </form>
</div>
