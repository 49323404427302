import { SidebarLinkDirective } from './sidebarlink.directive';
var SidebarAnchorToggleDirective = /** @class */ (function () {
    function SidebarAnchorToggleDirective(navlink) {
        this.navlink = navlink;
    }
    SidebarAnchorToggleDirective.prototype.onClick = function () {
        this.navlink.toggle();
    };
    return SidebarAnchorToggleDirective;
}());
export { SidebarAnchorToggleDirective };
