import { Observable } from 'rxjs';
import { MessageUnread } from '../models/message-unread.model';

import { InjectionToken } from '@angular/core';

export const MESSAGE_UNREAD_SERVICE = new InjectionToken<IMessageUnreadService>('Message unread service');

export interface IMessageUnreadService {
  getUnreadMessages(): Observable<MessageUnread[]>;
  getUnreadMessageCount(): Observable<number>;
}
