import { Router, } from '@angular/router';
import { AuthStoreService } from './services/auth-store.service';
var PfzGuard = /** @class */ (function () {
    function PfzGuard(router, authStore) {
        this.router = router;
        this.authStore = authStore;
    }
    PfzGuard.prototype.canActivate = function (route, state) {
        return this.getPfzGuardResult();
    };
    PfzGuard.prototype.canLoad = function (route, segments) {
        return this.getPfzGuardResult();
    };
    PfzGuard.prototype.canActivateChild = function (childRoute, state) {
        return this.getPfzGuardResult();
    };
    PfzGuard.prototype.getPfzGuardResult = function () {
        var claims = this.authStore.getUserClaims;
        var hasPFZClaim = claims.some(function (claim) { return claim.startsWith('Organization.Partners.PFZ'); });
        if (!hasPFZClaim) {
            this.router.navigate(['organizations']);
        }
        return true;
    };
    return PfzGuard;
}());
export { PfzGuard };
