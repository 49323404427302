var SidebarListDirective = /** @class */ (function () {
    function SidebarListDirective() {
        this.navlinks = [];
        this.activeLinks = [];
    }
    SidebarListDirective.prototype.setList = function (list) {
        this.navlinks = list;
    };
    SidebarListDirective.prototype.collapse = function (link) {
        link.open = false;
        this.setIsHidden(link);
        if (link.level.toString().trim() === '2') {
            this.activeLinks.push(this.navlinks.filter(function (_) { return _.level.toString().trim() === '1' && _.open === true; })[0].title);
        }
        link.toggleEmit.emit(this.activeLinks);
    };
    SidebarListDirective.prototype.expand = function (link) {
        link.open = true;
        this.activeLinks.push(link.title);
        this.collapseOtherLinks(link);
        this.setIsShown(link);
        link.toggleEmit.emit(this.activeLinks);
    };
    SidebarListDirective.prototype.toggleActiveList = function (openLink) {
        this.navlinks
            .filter(function (_) { return _.level.toString().trim() === '3'; })
            .forEach(function (link) {
            if (link !== openLink) {
                link.active = false;
            }
        });
    };
    SidebarListDirective.prototype.collapseOtherLinks = function (openLink) {
        var _this = this;
        if (openLink.level.toString().trim() === '1') {
            this.navlinks
                .filter(function (_) { return (_.level.toString().trim() === openLink.level.toString().trim() || _.level.toString().trim() === '2') && _.open === true; })
                .forEach(function (link) {
                if (link !== openLink) {
                    link.open = false;
                    _this.setIsHidden(link);
                }
            });
        }
        else if (openLink.level.toString().trim() === '2') {
            this.activeLinks.push(this.navlinks.filter(function (_) { return _.level.toString().trim() === '1' && _.open === true; })[0].title);
            this.navlinks
                .filter(function (_) { return _.level.toString().trim() === openLink.level.toString().trim() && _.parent === openLink.parent && _.open === true; })
                // tslint:disable-next-line: no-identical-functions
                .forEach(function (link) {
                if (link !== openLink) {
                    link.open = false;
                    _this.setIsHidden(link);
                }
            });
        }
    };
    SidebarListDirective.prototype.setIsShown = function (parentLink) {
        var childLevel = Number(parentLink.level) + 1;
        this.navlinks
            .filter(function (_) { return _.level.toString().trim() === childLevel.toString().trim() && _.parent === parentLink.title; })
            .forEach(function (link) {
            link.isShown = true;
            link.isHidden = false;
        });
    };
    SidebarListDirective.prototype.setIsHidden = function (parentLink) {
        var childLevel = Number(parentLink.level) + 1;
        this.navlinks
            .filter(function (_) { return _.level.toString().trim() === childLevel.toString().trim() && _.parent === parentLink.title; })
            .forEach(function (link) {
            link.isShown = false;
            link.isHidden = true;
        });
    };
    return SidebarListDirective;
}());
export { SidebarListDirective };
