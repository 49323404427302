import { EventEmitter } from '@angular/core';
var UserNavItemComponent = /** @class */ (function () {
    function UserNavItemComponent() {
        this.logout = new EventEmitter();
    }
    UserNavItemComponent.prototype.logoutClick = function (event) {
        this.logout.emit();
        event.stopPropagation();
    };
    return UserNavItemComponent;
}());
export { UserNavItemComponent };
