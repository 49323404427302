import { TranslateService } from '@ngx-translate/core';
import { AppConfigurationService, StorageService, SupportedLanguages } from 'crmcloud-core';
import { TranslationsLoader } from './translations-loader';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "crmcloud-core";
import * as i3 from "./translations-loader";
var TranslationInitializationService = /** @class */ (function () {
    function TranslationInitializationService(translate, appConfigurationService, translationsLoader, storageService) {
        this.translate = translate;
        this.appConfigurationService = appConfigurationService;
        this.translationsLoader = translationsLoader;
        this.storageService = storageService;
    }
    TranslationInitializationService.prototype.initializeTranslations = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var checkConfigLoaded = function () {
                if (_this.appConfigurationService.configuration.api_url) {
                    var lang_1 = _this.storageService.getItem('lang') || SupportedLanguages.PL;
                    _this.translationsLoader
                        .getTranslation(lang_1)
                        .toPromise()
                        .then(function (translations) {
                        _this.translate.setTranslation(lang_1, translations);
                        _this.translate.use(lang_1);
                        resolve(true);
                    })
                        .catch(reject);
                }
                else {
                    setTimeout(checkConfigLoaded, 100);
                }
            };
            checkConfigLoaded();
        });
    };
    TranslationInitializationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslationInitializationService_Factory() { return new TranslationInitializationService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.AppConfigurationService), i0.ɵɵinject(i3.TranslationsLoader), i0.ɵɵinject(i2.StorageService)); }, token: TranslationInitializationService, providedIn: "root" });
    return TranslationInitializationService;
}());
export { TranslationInitializationService };
