import { AppConfigurationService } from 'crmcloud-core';
var ɵ0 = function (window, appConfigurationService) { return ({
    auth: {
        clientId: appConfigurationService.configuration.ad_clientID,
        authority: appConfigurationService.configuration.ad_authority,
        validateAuthority: true,
        redirectUri: window.location.origin + '/',
        navigateToLoginRequestUrl: true,
    },
}); }, ɵ1 = function (appConfigurationService) { return ({
    consentScopes: [appConfigurationService.configuration.ad_clientID],
    popUp: false,
    protectedResourceMap: [[appConfigurationService.configuration.api_url, [appConfigurationService.configuration.ad_clientID]]],
}); };
var AuthModule = /** @class */ (function () {
    function AuthModule() {
    }
    return AuthModule;
}());
export { AuthModule };
export { ɵ0, ɵ1 };
