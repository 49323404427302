import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject, concat } from 'rxjs';
import { tap, delay, skip, takeUntil, concatMap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { AppConfigurationService } from 'crmcloud-core';
import { MessageCoreServicesModule } from '../message-core-services.module';
import { MessageCoreProxyService } from './message-core-proxy.service';
import { MessageDto } from '@crm-portal/core/message/models/message.dto';
import { IMessageUnreadService } from '@crm-portal/core/layout/navbar/message-unread-notification/common/services/message-unread.service';
import { MessageUnread } from '@crm-portal/core/layout/navbar/message-unread-notification/common/models/message-unread.model';

const MESSAGERE_FRESH_RATE_IN_MS = 60000;

@Injectable({
  providedIn: MessageCoreServicesModule,
})
export class MessageCoreNotificationService implements IMessageUnreadService {
  private readonly unreadMessageCount = new BehaviorSubject<number>(0);
  private readonly load$ = new BehaviorSubject('');
  private readonly refresh$ = new Subject<string>();
  private poll$: Observable<MessageDto[]>;
  private unreadMessageCount$: Observable<number>;

  constructor(
    private messageCoreProxyService: MessageCoreProxyService,
    private config: AppConfigurationService,
  ) {
    if (JSON.parse(this.config.configuration.messages_enabled)) {
      this.unreadMessageCount$ = this.unreadMessageCount.asObservable();

      const getData$ = this.messageCoreProxyService.getAllUnread().pipe(
        map(x => {
          return x.items;
        }),
        catchError(_ => {
          return of([]);
        }),
      );

      const whenToRefresh$ = of([]).pipe(
        delay(MESSAGERE_FRESH_RATE_IN_MS),
        tap(_ => this.load$.next('')),
        skip(1),
        takeUntil(this.refresh$),
      );

      this.poll$ = concat(getData$, whenToRefresh$);
    }
  }

  getUnreadMessages(): Observable<MessageUnread[]> {
    if (JSON.parse(this.config.configuration.messages_enabled)) {
      return this.load$
        .pipe(
          concatMap(_ => this.poll$),
          tap(data => {
            this.unreadMessageCount.next(data.length);
          }),
        )
        .pipe(
          map(messages =>
            messages.map(m => {
              return {
                id: m.id,
                type: m.type,
                title: m.title,
                body: m.body,
              } as MessageUnread;
            }),
          ),
        );
    }
  }

  getUnreadMessageCount(): Observable<number> {
    return this.unreadMessageCount$;
  }

  refresh() {
    this.refresh$.next('');
    this.load$.next('');
  }
}
