<ng-container [formGroup]="group">
  <app-button-form-control
    formControlName="{{ model.id }}"
    [icon]="model.buttonIcon"
    [label]="model.buttonLabel"
    [hide]="model.buttonHide"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    [touched]="control.touched"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)">
  </app-button-form-control>
</ng-container>
