<div
  class="container-fluid px-0"
  [appSpinner]="displaySpinner$ | async">
  <form
    #treatmentForm
    class="form-horizontal ng-dynamic-form"
    [formGroup]="formGroup"
    *ngIf="formGroup && formModel$ | async as formModel">
    <fieldset *ngIf="showAddPeriodButton">
      <button
        type="button"
        (click)="addItem()"
        class="btn btn-primary-outline float-right">
        <span>{{ translateKey + '.addPeriod' | translate }}</span>
        <i class="ph ph-plus"></i>
      </button>
    </fieldset>
    <fieldset>
      <dynamic-ng-bootstrap-form
        class="row no-gutters"
        [group]="formGroup"
        [layout]="formLayout"
        [model]="formModel">
        <ng-template
          modelType="GROUP"
          align="START"
          let-legend="legend">
          <p
            *ngIf="legend"
            class="form-group-legend m-0 mb-1">
            {{ legend }}
          </p>
        </ng-template>

        <ng-template
          modelId="periodsArrayModel"
          let-group
          let-index="index"
          let-context="context">
          <div class="periods-buttons">
            <button
              type="button"
              class="btn btn-danger"
              (click)="removeItem(context, index)"
              [disabled]="removeItemDisabled">
              <i class="ft-trash"></i>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="insertItem(group.context, group.index + 1)">
              <i class="ph ph-plus"></i>
            </button>
          </div>
        </ng-template>
      </dynamic-ng-bootstrap-form>
    </fieldset>
  </form>
  <div *ngIf="touched && hasError">
    <small class="invalid-feedback d-block ng-star-inserted">
      {{ errorTranslationKey | enumTranslateKey: 'APPLICATION.VALIDATORS' | translate }}
    </small>
  </div>
</div>
