import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var LayoutService = /** @class */ (function () {
    function LayoutService() {
        this.emitChangeSource = new Subject();
        this.changeEmitted$ = this.emitChangeSource.asObservable();
        // Customizer
        this.emitCustomizerSource = new Subject();
        this.customizerChangeEmitted$ = this.emitCustomizerSource.asObservable();
        // customizer - compact menu
        this.emitCustomizerCMSource = new Subject();
        this.customizerCMChangeEmitted$ = this.emitCustomizerCMSource.asObservable();
        // customizer - compact menu
        this.emitNotiSidebarSource = new Subject();
        this.notiSidebarChangeEmitted$ = this.emitNotiSidebarSource.asObservable();
    }
    LayoutService.prototype.emitChange = function (change) {
        this.emitChangeSource.next(change);
    };
    LayoutService.prototype.emitCustomizerChange = function (change) {
        this.emitCustomizerSource.next(change);
    };
    LayoutService.prototype.emitCustomizerCMChange = function (change) {
        this.emitCustomizerCMSource.next(change);
    };
    LayoutService.prototype.emitNotiSidebarChange = function (change) {
        this.emitNotiSidebarSource.next(change);
    };
    LayoutService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LayoutService_Factory() { return new LayoutService(); }, token: LayoutService, providedIn: "root" });
    return LayoutService;
}());
export { LayoutService };
