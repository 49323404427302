<agm-map
  [latitude]="mapConfig.latitude"
  [longitude]="mapConfig.longitude"
  [zoom]="mapConfig.zoom"
  [styles]="defaultMapStyles"
  [fullscreenControl]="mapConfig.fullscreenControl"
  [mapTypeControl]="mapConfig.mapTypeControl"
  [mapTypeControlOptions]="mapConfig.mapTypeControlOptions"
  [mapTypeId]="mapConfig.mapTypeId"
  [fitBounds]="fitBounds"
  (mapReady)="onMapReady($event)">
  <app-polygon
    [drawingManager]="drawingManager"
    [drawingManagerOptions]="drawingManagerOptions"
    [polygonConfig]="polygonConfig"
    [features]="features"
    [isDisabled]="isDisabled">
  </app-polygon>

  <app-marker
    [drawingManager]="drawingManager"
    [drawingManagerOptions]="drawingManagerOptions"
    [markerConfig]="markerConfig"
    [features]="features"
    [isDisabled]="isDisabled">
  </app-marker>

  <app-data-layer
    *ngIf="geoJsonUrl"
    [geoJsonUrl]="geoJsonUrl"></app-data-layer>
</agm-map>
