<ng-container [formGroup]="group">
  <lib-dual-list-form-control
    formControlName="{{ model.id }}"
    [items]="model.items"
    [bindLabel]="model.bindLabel"
    [readonly]="model.readonly"
    [translationPrefix]="model.translationPrefix"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    [touched]="control.touched"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)">
  </lib-dual-list-form-control>
</ng-container>
