<div
  ngbDropdown
  [placement]="'bottom-right'">
  <a
    class="nav-link position-relative"
    id="dropdownLang"
    ngbDropdownToggle>
    <i [ngClass]="['flag-icon', 'flag-icon-' + selectedLanguage.key]"></i>
  </a>
  <div
    aria-labelledby="dropdownLang"
    ngbDropdownMenu
    class="dropdownLang text-left">
    <a
      class="dropdown-item py-1 lang"
      href="javascript:;"
      (click)="changeLanguage(language.key)"
      *ngFor="let language of languages; trackBy: trackByFn">
      <i [ngClass]="['flag-icon', 'flag-icon-item', 'flag-icon-' + language.key]"></i>
      <span>{{ language.key | enumTranslateKey: 'APPLICATION.LANGUAGES' | translate }}</span>
    </a>
  </div>
</div>
