import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiListResponse, AppConfigurationService } from 'crmcloud-core';
import { AuthStoreService } from '@crm-portal/core/auth/services/auth-store.service';
import { MessageApiListResponse, MessageDto } from '@crm-portal/core/message/models/message.dto';
import { CreateMessageCommand } from '@crm-portal/core/message/models/message-command.model';
import { BaseProxyService } from '@crm-portal/core/common/base-proxy-service';
import { RequestListGridify } from '@crm-portal/core/common/request-list-gridify';
import { MessageListFilter, MessageListOrder } from '@crm-portal/core/message/models/message-list-filter.model';

@Injectable({
  providedIn: 'root',
})
export class MessageCoreProxyService extends BaseProxyService {
  private readonly baseApiUrl: string;

  constructor(
    config: AppConfigurationService,
    private userContextService: AuthStoreService,
    private httpClient: HttpClient,
  ) {
    super();
    this.baseApiUrl = `${config.configuration.api_url}/api/messages/v1/message`;
  }

  public get(
    requestList: RequestListGridify<MessageListFilter, MessageListOrder>,
    isAlertMode: boolean,
  ): Observable<MessageApiListResponse> {
    if (!requestList.filter) {
      requestList.filter = [];
    }

    if (isAlertMode) {
      requestList.filter.push({
        field: 'RecipientOrganizationId',
        value: this.userContextService.getUserOrganizationId,
        condition: '=',
      });
    }

    const params = super.prepareQueryGridifyParams(requestList);
    return this.httpClient.get<MessageApiListResponse>(`${this.baseApiUrl}`, { params });
  }

  public getAllUnread(): Observable<ApiListResponse<MessageDto>> {
    const requestList: RequestListGridify<MessageListFilter, MessageListOrder> = {
      filter: [
        {
          field: 'RecipientOrganizationId',
          value: this.userContextService.getUserOrganizationId,
          condition: '=',
        },
        {
          field: 'IsRead',
          value: false,
          condition: '=',
        },
      ],
      page: 1,
      pageSize: 20,
    };
    const params = super.prepareQueryGridifyParams(requestList);
    return this.httpClient.get<ApiListResponse<MessageDto>>(`${this.baseApiUrl}`, { params });
  }

  public createMessage(messageCommand: CreateMessageCommand): Observable<void> {
    return this.httpClient.post<void>(this.baseApiUrl, messageCommand);
  }

  public markAsRead(id: string): Observable<MessageDto> {
    const url = `${this.baseApiUrl}`;
    const body = { id: `${id}` };
    return this.httpClient.patch<MessageDto>(url, body);
  }
}
