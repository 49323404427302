import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FullLayoutComponent } from '@crm-portal/core/layout';
import { ProductCatalogAuditViewType } from 'crmcloud-core';
import { SiteTitle } from './app-site-titles.enum';
import { IdpAuthGuard } from './core/auth/idp-auth.guard';
import { IdpOrganizationGuard } from './core/auth/idp-organization.guard';
import { PfzGuard } from '@crm-portal/core/auth/pfz-guard.service';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: './modules/authorization/authorization.module#AuthorizationModule',
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'suppliers',
        pathMatch: 'full',
      },
      {
        path: 'users',
        loadChildren: './modules/user/user.module#UserModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.users,
        },
      },
      {
        path: 'carbon-exchange',
        loadChildren: './modules/carbon-exchange/carbon-exchange.module#CarbonExchangeModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.carbonExchange,
        },
      },
      {
        path: 'cultivation-assistant',
        loadChildren: './modules/cultivation-assistant/cultivation-assistant.module#CultivationAssistantModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.cultivationAssistant,
        },
      },
      {
        path: 'suppliers',
        loadChildren: './modules/supplier/supplier.module#SupplierModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.suppliers,
        },
      },
      {
        path: 'contracts',
        loadChildren: './modules/contract/contract.module#ContractModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
      },
      {
        path: 'deliveries',
        loadChildren: './modules/delivery/delivery.module#DeliveryModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
      },
      {
        path: 'organizations',
        loadChildren: './modules/organization/organization.module#OrganizationModule',
        canLoad: [IdpAuthGuard],
        data: {
          title: SiteTitle.organizations,
        },
      },
      {
        path: 'contacts',
        loadChildren: './modules/contact/contact.module#ContactModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.contacts,
        },
      },
      {
        path: 'employee-tasks',
        loadChildren: './modules/employee-task/employee-task.module#EmployeeTaskModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
      },
      {
        path: 'farm-audits',
        loadChildren: './modules/farm-audit/farm-audit.module#FarmAuditModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
      },
      {
        path: 'field-audits',
        loadChildren: './modules/field-audit/field-audit.module#FieldAuditModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
      },
      {
        path: 'product-catalog-audits',
        loadChildren: './modules/product-catalog-audit/product-catalog-audit.module#ProductCatalogAuditModule',
        data: { viewType: ProductCatalogAuditViewType.Technologist },
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
      },
      {
        path: 'product-catalog-audit-results',
        loadChildren: './modules/product-catalog-audit/product-catalog-audit.module#ProductCatalogAuditModule',
        data: { viewType: ProductCatalogAuditViewType.SecurityOffice },
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
      },
      {
        path: 'messages',
        loadChildren: './modules/message/message.module#MessageModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
      },
      {
        path: 'purchase-places',
        loadChildren: './modules/purchase-places/purchase-places.module#PurchasePlacesModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard, PfzGuard],
        data: {
          title: SiteTitle.purchasePlaces,
        },
      },
      {
        path: 'purchase-places-quotes',
        loadChildren: './modules/purchase-places-quotes/purchase-places-quotes.module#PurchasePlacesQuotesModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard, PfzGuard],
        data: {
          title: SiteTitle.purchasePlacesQuote,
        },
      },
      {
        path: 'notes',
        loadChildren: './modules/note/note.module#NoteModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.notes,
        },
      },
      {
        path: 'rucip',
        loadChildren: './modules/rucip-observation/rucip-observation.module#RucipObservationModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard, PfzGuard],
        data: {
          title: SiteTitle.rucip,
        },
      },
      {
        path: 'support',
        loadChildren: './modules/support/support.module#SupportModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.support,
        },
      },
      {
        path: 'pesticides',
        loadChildren: './modules/pesticide/pesticide.module#PesticideModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.pesticides,
        },
      },
      {
        path: 'seeds',
        loadChildren: './modules/seed/seed.module#SeedModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.seeds,
        },
      },
      {
        path: 'fertilizers-db',
        loadChildren: './modules/fertilizer-db/fertilizer-db.module#FertilizerDbModule',
        data: {
          title: SiteTitle.fertilizersDb,
        },
      },
      {
        path: 'catalog-definitions',
        loadChildren: './modules/catalog-definition/catalog-definition.module#CatalogDefinitionModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.calalogDefinitions,
        },
      },
      {
        path: 'products',
        loadChildren: './modules/product/product.module#ProductModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
      },
      {
        path: 'fertilizer-stock-needs',
        loadChildren: './modules/fertilizer-stock-needs/fertilizer-stock-needs.module#FertilizerStockNeedsModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
        data: {
          title: SiteTitle.fertilizerStockNeeds,
        },
      },
      {
        path: 'transport-calculations',
        loadChildren: './modules/transport-calculation/transport-calculation.module#TransportCalculationModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
      },
      {
        path: 'report',
        loadChildren: './modules/report/report.module#ReportModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
      },
      {
        path: 'observations',
        loadChildren: './modules/observation/observation.module#ObservationModule',
        canLoad: [IdpAuthGuard],
        canActivateChild: [IdpOrganizationGuard],
      },
      {
        path: '**',
        redirectTo: 'dashboard',
      },
    ],
    canActivate: [IdpAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
