import { HttpParams } from '@angular/common/http';
var BaseProxyService = /** @class */ (function () {
    function BaseProxyService() {
    }
    BaseProxyService.prototype.prepareQueryGridifyParams = function (requestList) {
        var params = new HttpParams();
        params = this.preparePaginationQueryParams(params, requestList.page, requestList.pageSize);
        params = this.prepareOrderingQueryParams(params, requestList.orderBy);
        params = this.prepareFilterByQueryParams(params, requestList.filter);
        return params;
    };
    BaseProxyService.prototype.prepareFilterByQueryParams = function (httpParams, filterList) {
        if (!filterList) {
            return httpParams;
        }
        var filterBy = '';
        filterList.forEach(function (item) {
            var value = item.value;
            if (filterBy.length > 0) {
                filterBy += ',';
            }
            if (value === null) {
                filterBy += "" + item.field + item.condition;
                return;
            }
            if (typeof value === 'string') {
                value = value.replace(/([(),|\\]|\/i)/g, '\\$1');
            }
            filterBy += "" + item.field + item.condition + value;
        });
        var params = httpParams;
        if (filterBy.length > 0) {
            params = params.set('FilterBy', filterBy);
        }
        return params;
    };
    BaseProxyService.prototype.preparePaginationQueryParams = function (httpParams, page, pageSize) {
        var params = httpParams;
        if (page != null) {
            params = params.set('page', String(page));
        }
        if (pageSize != null) {
            params = params.set('pageSize', String(pageSize));
        }
        return params;
    };
    BaseProxyService.prototype.prepareOrderingQueryParams = function (httpParams, orderBy) {
        if (!orderBy) {
            return httpParams;
        }
        var orderByParams = '';
        orderBy.forEach(function (x) {
            var value = x.field;
            if (!value) {
                return;
            }
            if (orderByParams.length > 0) {
                orderByParams += ',';
            }
            orderByParams += value + " " + x.sortOrder;
        });
        var params = httpParams;
        if (orderByParams.length > 0) {
            params = params.set('OrderBy', orderByParams);
        }
        return params;
    };
    return BaseProxyService;
}());
export { BaseProxyService };
