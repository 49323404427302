/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-nav-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./user-nav-item.component";
var styles_UserNavItemComponent = [i0.styles];
var RenderType_UserNavItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserNavItemComponent, data: {} });
export { RenderType_UserNavItemComponent as RenderType_UserNavItemComponent };
export function View_UserNavItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(1, 1720320, null, 3, i2.NgbDropdown, [i1.ChangeDetectorRef, i2.NgbDropdownConfig, i3.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i2.NgbNavbar]], { placement: [0, "placement"] }, null), i1.ɵqud(603979776, 1, { _menu: 0 }), i1.ɵqud(603979776, 2, { _menuElement: 0 }), i1.ɵqud(603979776, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["aria-haspopup", "true"], ["class", "nav-link position-relative dropdown-toggle"], ["id", "dropdownBasic3"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.NgbDropdownToggle, [i2.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i2.NgbDropdownAnchor, null, [i2.NgbDropdownToggle]), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "ph ph-dots-three"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[2, 0]], null, 7, "div", [["aria-labelledby", "dropdownBasic3"], ["class", "text-left"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 10).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, [[1, 4]], 1, i2.NgbDropdownMenu, [i2.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵeld(12, 0, null, null, 4, "a", [["class", "dropdown-item p-2 d-flex align-items-center"], ["href", "javascript:;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logoutClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "ph ph-power mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "top left"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_2); var currVal_3 = true; var currVal_4 = i1.ɵnov(_v, 10).dropdown.isOpen(); var currVal_5 = i1.ɵnov(_v, 10).placement; _ck(_v, 9, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform("APPLICATION.NAVBAR.logout")); _ck(_v, 15, 0, currVal_6); }); }
export function View_UserNavItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-nav-item", [], null, null, null, View_UserNavItemComponent_0, RenderType_UserNavItemComponent)), i1.ɵdid(1, 49152, null, 0, i5.UserNavItemComponent, [], null, null)], null, null); }
var UserNavItemComponentNgFactory = i1.ɵccf("app-user-nav-item", i5.UserNavItemComponent, View_UserNavItemComponent_Host_0, {}, { logout: "logout" }, []);
export { UserNavItemComponentNgFactory as UserNavItemComponentNgFactory };
