import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { AppConfigurationService } from 'crmcloud-core';
import { AuthStoreService } from '@crm-portal/core/auth/services/auth-store.service';
import { BaseProxyService } from '@crm-portal/core/common/base-proxy-service';
import * as i0 from "@angular/core";
import * as i1 from "crmcloud-core";
import * as i2 from "../../auth/services/auth-store.service";
import * as i3 from "@angular/common/http";
var MessageCoreProxyService = /** @class */ (function (_super) {
    tslib_1.__extends(MessageCoreProxyService, _super);
    function MessageCoreProxyService(config, userContextService, httpClient) {
        var _this = _super.call(this) || this;
        _this.userContextService = userContextService;
        _this.httpClient = httpClient;
        _this.baseApiUrl = config.configuration.api_url + "/api/messages/v1/message";
        return _this;
    }
    MessageCoreProxyService.prototype.get = function (requestList, isAlertMode) {
        if (!requestList.filter) {
            requestList.filter = [];
        }
        if (isAlertMode) {
            requestList.filter.push({
                field: 'RecipientOrganizationId',
                value: this.userContextService.getUserOrganizationId,
                condition: '=',
            });
        }
        var params = _super.prototype.prepareQueryGridifyParams.call(this, requestList);
        return this.httpClient.get("" + this.baseApiUrl, { params: params });
    };
    MessageCoreProxyService.prototype.getAllUnread = function () {
        var requestList = {
            filter: [
                {
                    field: 'RecipientOrganizationId',
                    value: this.userContextService.getUserOrganizationId,
                    condition: '=',
                },
                {
                    field: 'IsRead',
                    value: false,
                    condition: '=',
                },
            ],
            page: 1,
            pageSize: 20,
        };
        var params = _super.prototype.prepareQueryGridifyParams.call(this, requestList);
        return this.httpClient.get("" + this.baseApiUrl, { params: params });
    };
    MessageCoreProxyService.prototype.createMessage = function (messageCommand) {
        return this.httpClient.post(this.baseApiUrl, messageCommand);
    };
    MessageCoreProxyService.prototype.markAsRead = function (id) {
        var url = "" + this.baseApiUrl;
        var body = { id: "" + id };
        return this.httpClient.patch(url, body);
    };
    MessageCoreProxyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageCoreProxyService_Factory() { return new MessageCoreProxyService(i0.ɵɵinject(i1.AppConfigurationService), i0.ɵɵinject(i2.AuthStoreService), i0.ɵɵinject(i3.HttpClient)); }, token: MessageCoreProxyService, providedIn: "root" });
    return MessageCoreProxyService;
}(BaseProxyService));
export { MessageCoreProxyService };
