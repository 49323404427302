import { OnInit } from '@angular/core';
import { IMessageUnreadService } from '../common/services/message-unread.service';
var MessageUnreadNotificationComponent = /** @class */ (function () {
    function MessageUnreadNotificationComponent(messageUnreadService) {
        this.messageUnreadService = messageUnreadService;
    }
    MessageUnreadNotificationComponent.prototype.ngOnInit = function () {
        this.unreadMessages$ = this.messageUnreadService.getUnreadMessages();
        this.unreadMessageCount$ = this.messageUnreadService.getUnreadMessageCount();
    };
    MessageUnreadNotificationComponent.prototype.trackByFn = function (index, message) {
        return message.id;
    };
    return MessageUnreadNotificationComponent;
}());
export { MessageUnreadNotificationComponent };
