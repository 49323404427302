<ng-container [formGroup]="group">
  <ng-select
    [id]="elementId"
    [class.is-invalid]="showErrorMessages"
    formControlName="{{ model.id }}"
    [required]="model.required"
    [tabIndex]="model.tabIndex"
    (change)="onChange($event)"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    [items]="options$ | async"
    [multiple]="model.multiple"
    [clearable]="false"
    [placeholder]="model.placeholder"
    [readonly]="false"
    [bindValue]="'value'"
    [notFoundText]="'APPLICATION.FORM.multiSelect:notFoundText' | translate"
    [searchFn]="customSearchFn">
    <ng-template
      ng-label-tmp
      let-item="item">
      {{ item.label | translate }}
    </ng-template>

    <ng-template
      ng-option-tmp
      let-item="item"
      let-index="index"
      let-search="searchTerm">
      {{ item.label | translate }}
    </ng-template>
  </ng-select>
</ng-container>
