<!-- Navbar (Header) Starts -->
<nav class="header-navbar navbar navbar-expand-lg navbar-light bg-faded header-menu">
  <div class="navbar-header d-flex align-items-center d-lg-none justify-content-between">
    <button
      type="button"
      class="btn btn-primary-outline btn-icon navbar-toggle d-lg-none float-left"
      data-toggle="collapse"
      (click)="toggleSidebar()">
      <i class="ph ph-list"></i>
    </button>
    <img
      mainLogo
      class="logo-farmportal d-lg-none"
      [src]="logoUrl" />
  </div>
  <div class="navbar-container">
    <ng-content></ng-content>
  </div>
</nav>
<!-- Navbar (Header) Ends -->
