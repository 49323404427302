import { Injectable } from '@angular/core';

import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigurationService } from 'crmcloud-core';
import { ResetPasswordDto } from '../models/reset-password.dto';
import { TokenAuthResultDto } from '../models/token-auth-result.dto';
import { UserCredentials } from '../models/user-credentials.dto';
import { PartnerRegisterDto } from '../models/partner-register.dto';
import { RefreshTokenInputDto } from '../models/refresh-token-input.dto';
import { LoginSocialDto } from '../models/ms-header-token.dto';
import { ExternalLoginProviderType } from '../models/external-login-provider-type.enum';
import { AGRI_CRM_TOKEN } from '../models/auth-models';
import { RemindPasswordDto } from '../models/remind-password.dto';
import { ConfirmEmailInputDto } from '../models/confirm-email-input.model';
import { SendEmailActivationLinkInputDto } from '../models/send-email-activation-link-input.model';
import { UserProfileDto } from '@crm-portal/core/auth/models/user-profile.dto';

@Injectable({
  providedIn: 'root',
})
export class AuthProxyService {
  private readonly baseTokenAuthUrl: string;
  private readonly baseUserAuthUrl: string;
  private readonly baseUserProfileUrl: string;
  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
    config: AppConfigurationService,
  ) {
    this.baseTokenAuthUrl = `${config.configuration.api_url}/api/TokenAuth`;
    this.baseUserAuthUrl = `${config.configuration.api_url}/api/UserAuth`;
    this.baseUserProfileUrl = `${config.configuration.api_url}/api/UserProfile`;
  }

  public tokenSocial(tokenRequest: any, provider: ExternalLoginProviderType): Observable<TokenAuthResultDto> {
    const url = `${this.baseTokenAuthUrl}/ExternalAuthenticate`;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    const payload: LoginSocialDto = {
      authProvider: provider,
      host: AGRI_CRM_TOKEN,
    };
    if (provider === ExternalLoginProviderType.MICROSOFT && tokenRequest !== null) {
      payload.msToken = tokenRequest;
    }

    const options = { headers };
    return this.http.post<TokenAuthResultDto>(url, payload, options);
  }

  public token(tokenRequestDto: UserCredentials): Observable<TokenAuthResultDto> {
    const url = `${this.baseTokenAuthUrl}/Authenticate`;
    return this.http.post(url, tokenRequestDto).pipe(map(res => res as TokenAuthResultDto));
  }

  public getUserProfile(): Observable<UserProfileDto> {
    const url = `${this.baseUserProfileUrl}`;
    return this.http.get<UserProfileDto>(url);
  }

  public refreshToken(refreshTokenDto: RefreshTokenInputDto): Observable<TokenAuthResultDto> {
    const httpClient = new HttpClient(this.httpBackend);
    const url = `${this.baseTokenAuthUrl}/RefreshToken`;
    return httpClient.post<TokenAuthResultDto>(url, refreshTokenDto);
  }

  public register(input: PartnerRegisterDto): Observable<unknown> {
    const url = `${this.baseUserAuthUrl}/RegisterPartner`;
    return this.http.post(url, input || {});
  }

  public remindPassword(data: RemindPasswordDto): Observable<unknown> {
    const url = `${this.baseUserAuthUrl}/RemindPassword`;
    return this.http.post(url, data || {});
  }

  public resetPassword(data: ResetPasswordDto) {
    const url = `${this.baseUserAuthUrl}/ResetPassword`;
    return this.http.post(
      url,
      {
        ...data,
      } || {},
    );
  }

  public confirmEmail(input: ConfirmEmailInputDto) {
    const url = `${this.baseUserAuthUrl}/ConfirmEmail`;
    return this.http.post(url, input || {});
  }

  public sendEmailActivationLink$(input: SendEmailActivationLinkInputDto) {
    const url = `${this.baseUserAuthUrl}/SendEmailActivationLink`;
    return this.http.post(url, input || {});
  }
}
