import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MessageUnread } from '../common/models/message-unread.model';
import { MESSAGE_UNREAD_SERVICE, IMessageUnreadService } from '../common/services/message-unread.service';

@Component({
  selector: 'app-message-unread-notification',
  templateUrl: './message-unread-notification.component.html',
  styleUrls: ['./message-unread-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageUnreadNotificationComponent implements OnInit {
  unreadMessages$;
  unreadMessageCount$;

  constructor(
    @Inject(MESSAGE_UNREAD_SERVICE)
    private messageUnreadService: IMessageUnreadService,
  ) {}

  ngOnInit() {
    this.unreadMessages$ = this.messageUnreadService.getUnreadMessages();
    this.unreadMessageCount$ = this.messageUnreadService.getUnreadMessageCount();
  }

  trackByFn(index: number, message: MessageUnread) {
    return message.id;
  }
}
