<ng-container [formGroup]="group">
  <app-machine-alarm-conf-element-form-control
    formControlName="{{ model.id }}"
    [translateKey]="model.translateKey"
    [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]"
    (blur)="onBlur($event)"
    (change)="onChange($event)"
    (focus)="onFocus($event)">
  </app-machine-alarm-conf-element-form-control>
</ng-container>
