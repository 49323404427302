import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LoggerService, SpinnerService, TranslateService } from 'crmcloud-core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public isLoading$ = this.spinnerService.status$;

  constructor(
    private translateService: TranslateService,
    private loggerService: LoggerService,
    private readonly spinnerService: SpinnerService,
  ) {}

  ngOnInit(): void {
    this.translateService.initialize();
    this.loggerService.initialize();
  }
}
