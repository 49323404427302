<div
  [formGroup]="form"
  class="form-inline"
  [class.input-group]="model.prefix || model.suffix">
  <div
    *ngIf="model.prefix"
    class="input-group-addon"
    [innerHTML]="model.prefix"></div>
  <div class="form-group">
    <select
      class="form-control"
      [compareWith]="model.compareWithFn"
      formControlName="comparison"
      [name]="model.name + '_select'"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)">
      <option
        *ngFor="let option of model.options$ | async"
        [disabled]="option.disabled"
        [ngValue]="option.value">
        {{ option.label }}
      </option>
    </select>
  </div>
  <div class="form-group mx-sm-3">
    <input
      class="form-control"
      [attr.accept]="model.accept"
      [attr.max]="model.max"
      [attr.min]="model.min"
      [attr.step]="model.step"
      formControlName="value"
      [name]="model.name + '_input'"
      [placeholder]="model.placeholder"
      [required]="model.required"
      [tabindex]="model.tabIndex"
      [type]="model.inputType"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)" />
  </div>
</div>
