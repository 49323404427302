import { RouteGroup } from '@crm-portal/core/layout/sidebar/sidebar.metadata';

export const ROUTES: RouteGroup[] = [
  {
    groupTitle: 'APPLICATION.MENU.activities',
    routeGroup: [
      {
        path: '/organizations',
        title: 'APPLICATION.MENU.organizations',
        icon: 'ph-duotone ph-barn',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/suppliers',
        title: 'APPLICATION.MENU.suppliers',
        icon: 'ph-duotone ph-building-office',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '/contacts',
        title: 'APPLICATION.MENU.contacts',
        icon: 'ph-duotone ph-user-list',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.ManageOrganizations'],
      },
      {
        path: '/users',
        title: 'APPLICATION.MENU.organizationManagement',
        icon: 'ph-duotone ph-users-three',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Administration'],
      },
    ],
  },
  {
    groupTitle: 'APPLICATION.MENU.operations',
    routeGroup: [
      {
        path: '/cultivation-assistant',
        title: 'APPLICATION.MENU.cultivationAssistant',
        icon: 'ph-duotone ph-plant',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Grounds'],
      },
      {
        path: '/contracts',
        title: 'APPLICATION.MENU.contracts',
        icon: 'ph-duotone ph-clipboard-text',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.ManageOrganizations.Contracts'],
      },
      {
        path: '/purchase-places',
        title: 'APPLICATION.MENU.purchasePlaces',
        icon: 'ph-duotone ph-shopping-cart',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Partners.PFZ'],
      },
      {
        path: '/purchase-places-quotes',
        title: 'APPLICATION.MENU.purchasePlacesQuotes',
        icon: 'ph-duotone ph-shopping-cart',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Partners.PFZ'],
      },
      {
        path: '/carbon-exchange',
        title: 'APPLICATION.MENU.carbonExchange',
        icon: 'ph-duotone ph-chart-line',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
      },
      {
        path: '',
        title: 'APPLICATION.MENU.deliveries',
        icon: 'ph-duotone ph-medal',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        components: ['Organization.Transport'],
        submenu: [
          {
            path: '/deliveries/list',
            title: 'APPLICATION.MENU.DELIVERIES.list',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Transport'],
          },
          {
            path: '/deliveries/reports/suppliers',
            title: 'APPLICATION.MENU.DELIVERIES.reportSuppliers',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Transport'],
          },
          {
            path: '/deliveries/reports/products',
            title: 'APPLICATION.MENU.DELIVERIES.reportProducts',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Transport'],
          },
        ],
      },
      {
        path: '',
        title: 'APPLICATION.MENU.audits',
        icon: 'ph-duotone ph-medal',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        components: ['Organization.Lab', 'Organization.Audits', 'Organization.Staff.Tasks'],
        submenu: [
          {
            path: '/employee-tasks',
            title: 'APPLICATION.MENU.AUDITS.employeeTasks',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Staff.Tasks'],
          },
          {
            path: '/farm-audits',
            title: 'APPLICATION.MENU.AUDITS.farmAudits',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Audits'],
          },
          {
            path: '/field-audits',
            title: 'APPLICATION.MENU.AUDITS.fieldAudits',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Audits'],
          },
          {
            path: '/product-catalog-audits',
            title: 'APPLICATION.MENU.AUDITS.productCatalogAuditsForTechnologist',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Lab'],
          },
          {
            path: '/product-catalog-audit-results',
            title: 'APPLICATION.MENU.AUDITS.productCatalogAuditsForSecurityOffice',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Lab'],
          },
        ],
      },
      {
        path: '/support',
        title: 'APPLICATION.MENU.support',
        icon: 'ph-duotone ph-headset',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.ManageOrganizations'],
      },
    ],
  },
  {
    groupTitle: 'APPLICATION.MENU.resources',
    routeGroup: [
      {
        path: '/rucip',
        title: 'APPLICATION.MENU.rucip',
        icon: 'ph-duotone ph-note',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Partners.PFZ'],
      },
      {
        path: '/fertilizer-stock-needs',
        title: 'APPLICATION.MENU.fertilizerStockNeeds',
        icon: 'ph-duotone ph-grains',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.ManageOrganizations'],
      },
      {
        path: '/products',
        title: 'APPLICATION.MENU.products',
        icon: 'ph-duotone ph-package',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.Trade.Products'],
      },
      {
        path: '',
        title: 'APPLICATION.MENU.databases',
        icon: 'ph-duotone ph-database',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        components: ['Organization.Trade', 'Organization.Trade.Catalog'],
        submenu: [
          {
            path: '/pesticides',
            title: 'APPLICATION.MENU.pesticides',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Trade'],
          },
          {
            path: '/seeds',
            title: 'APPLICATION.MENU.seeds',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Trade'],
          },
          {
            path: '/fertilizers-db',
            title: 'APPLICATION.MENU.fertilizersDb',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
          },
          {
            path: '/catalog-definitions',
            title: 'APPLICATION.MENU.catalogDefinitions',
            icon: '',
            class: '',
            badge: '',
            badgeClass: '',
            isExternalLink: false,
            submenu: [],
            components: ['Organization.Trade.Catalog'],
          },
        ],
      },
      {
        path: '/notes',
        title: 'APPLICATION.MENU.notes',
        icon: 'ph-duotone ph-notepad',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.ManageOrganizations'],
      },
      {
        path: '/transport-calculations',
        title: 'APPLICATION.MENU.transportCalculations',
        icon: 'ph-duotone ph-van',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        components: ['Organization.ManageOrganizations.Transport'],
      },
    ],
  },
  // {
  //   path: '',
  //   title: 'APPLICATION.MENU.reports',
  //   icon: 'ft-trending-up',
  //   class: 'has-sub',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   components: ['Organization.Report'],
  //   submenu: [
  //     {
  //       path: '/report/fertilizer-report',
  //       title: 'APPLICATION.MENU.fertilizerReport',
  //       icon: '',
  //       class: '',
  //       badge: '',
  //       badgeClass: '',
  //       isExternalLink: false,
  //       submenu: [],
  //       components: ['Organization.Report.Fertilizers'],
  //     },
  //   ],
  // },
];
